import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import AddNotes from "../../CommonModals/AddNotesModal"
// config file

const Projects = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Projects");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Name",
        derivedValue:
          "name=name=name=name=name=name=name=name=ProjectName=ProjectName=ProjectName=ProjectName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "Start Date",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Start Date",
        derivedValue:
          "startDate=startDate=startDate=startDate=startDate=startDate=startDate=StartDate=startDate=StartDate=StartDate=StartDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teamLead",
        fieldName: "teamLead",
        type: "relateAutoComplete",
        placeholder: "TeamLead",
        value: "",
        label: "Team Lead",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Team Lead",
        derivedValue:
          "teamLead=teamLead=teamLead=teamLead=teamLead=TeamLead=teamLead=TeamLead=TeamLead=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teamLead",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "teamLead",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "Active",
        label: "Status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=Status=status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Closed", value: "Closed", color: "danger" },
        ],
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "Closed", value: "Closed", color: "warning" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Actions",
        type: "checkbox",
        placeholder: "Actions",
        label: "Actions",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Actions",
        derivedValue: "Actions=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Actions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        displayinServer: "True",
        serverFieldType: "String",
        show: true,
        field: "Actions",
        fieldType: "Actions",
        showOrHideFields: [],
        fieldName: "Actions",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attach File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Attatch File",
        derivedValue: "attatchFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "Project",
        textAlign: "center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Description",
        derivedValue:
          "description=description=description=description=Description=description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '500px', textOverflow: "ellipsis" },
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignEmployees",
        fieldName: "assignEmployees",
        type: "relateAutoComplete",
        placeholder: "assignEmployees",
        value: "",
        label: "Assign Employees",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Team Members",
        derivedValue:
          "assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=Team_Members=Team_Members=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignEmployees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: true,
        textAlign: "center",
        style: { width: '300px', textOverflow: "ellipsis" },
        show: true,
        field: "assignEmployees",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teamSize",
        fieldName: "teamSize",
        type: "number",
        placeholder: "teamSize",
        value: "",
        label: "Team Size",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Team Size",
        derivedValue: "teamSize=teamSize=teamSize=teamSize=teamSize=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teamSize",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "teamSize",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "prefix",
        fieldName: "prefix",
        type: "text",
        placeholder: "prefix",
        value: "",
        label: "Project Key",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Project Key",
        derivedValue: "prefix=prefix=prefix=prefix=prefix=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "prefix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "prefix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientName",
        fieldName: "clientName",
        type: "text",
        placeholder: "Client Name",
        value: "",
        label: "Client Name",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Client Name",
        derivedValue: "clientName=clientName=clientName=clientName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "clientName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientPhoneNumber",
        fieldName: "clientPhoneNumber",
        type: "text",
        placeholder: "clientPhoneNumber",
        value: "",
        label: "Client Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Client Phone Number",
        derivedValue:
          "clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientPhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "clientPhoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientAddress",
        fieldName: "clientAddress",
        type: "textarea",
        placeholder: "clientAddress",
        value: "",
        label: "Client Address",
        width: "200px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Client Address",
        derivedValue: "clientAddress=clientAddress=clientAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "clientAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "showsTaskId",
        fieldName: "showsTaskId",
        type: "radio",
        placeholder: "Show TaskId",
        value: "",
        label: "Show TaskId",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Show TaskId",
        derivedValue:
          "showsTaskId=showsTaskId=showsTaskId=showsTaskId=showsTaskId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "showsTaskId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        textAlign: "center",
        show: true,
        field: "showsTaskId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Project Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue:
          "name=name=name=name=name=name=name=name=ProjectName=ProjectName=ProjectName=ProjectName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        validationRequired: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "Start Date",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "startDate=StartDate=StartDate=StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "teamLead",
        fieldName: "teamLead",
        type: "relateAutoComplete",
        placeholder: "Team Lead",
        value: "",
        label: "Team Lead",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "teamLead=TeamLead=TeamLead=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "teamLead",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "teamLead",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "Active",
        label: "Status",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Closed", value: "Closed", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Active", value: "Active", color: "primary" },
          { label: "Closed", value: "Closed", color: "secondary" },
          { label: "Pending", value: "Pending", color: "success" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attach File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "attatchFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "attatchFile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: true,
        imagePath: "Project",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue:
          "description=description=description=description=Description=description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignEmployees",
        fieldName: "assignEmployees",
        type: "relateAutoComplete",
        placeholder: "Assign Employees",
        value: "",
        label: "Team Members",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue:
          "assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=Team_Members=Team_Members=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "assignEmployees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "prefix",
        fieldName: "prefix",
        type: "text",
        placeholder: "Prefix",
        value: "",
        label: "Project Key",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "prefix=prefix=prefix=prefix=prefix=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "prefix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clientName",
        fieldName: "clientName",
        type: "text",
        placeholder: "Client Name",
        value: "",
        label: "Client Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "clientName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clientPhoneNumber",
        fieldName: "clientPhoneNumber",
        type: "text",
        placeholder: "Client Phone Number",
        value: "",
        label: "Client Phone Number",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientPhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clientAddress",
        fieldName: "clientAddress",
        type: "textarea",
        placeholder: "Client Address",
        value: "",
        label: "Client Address",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "clientAddress=clientAddress=clientAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "showsTaskId",
        fieldName: "showsTaskId",
        type: "radio",
        placeholder: "Show TaskId",
        value: "No",
        label: "Show TaskId",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue:
          "showsTaskId=showsTaskId=showsTaskId=showsTaskId=showsTaskId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "showsTaskId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Name",
        derivedValue:
          "name=name=name=name=name=name=name=name=ProjectName=ProjectName=ProjectName=ProjectName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "startDate",
        fieldName: "startDate",
        type: "date",
        placeholder: "Start Date",
        value: "",
        label: "Start Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Start Date",
        derivedValue:
          "startDate=startDate=startDate=startDate=startDate=startDate=startDate=StartDate=startDate=StartDate=StartDate=StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teamLead",
        fieldName: "teamLead",
        type: "relateAutoComplete",
        placeholder: "TeamLead",
        value: "",
        label: "Team Lead",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Team Lead",
        derivedValue:
          "teamLead=teamLead=teamLead=teamLead=teamLead=TeamLead=teamLead=TeamLead=TeamLead=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teamLead",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "teamLead",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "Active",
        label: "Status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=Status=status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Closed", value: "Closed", color: "danger" },
        ],
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "Closed", value: "Closed", color: "warning" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Actions",
        type: "checkbox",
        placeholder: "Actions",
        label: "Actions",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Actions",
        derivedValue: "Actions=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Actions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        displayinServer: "True",
        serverFieldType: "String",
        show: true,
        field: "Actions",
        fieldType: "Actions",
        showOrHideFields: [],
        fieldName: "Actions",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        fieldName: "image",
        type: "fileUpload",
        placeholder: "Attach File",
        value: "",
        label: "Attach File",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Attatch File",
        derivedValue: "attatchFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "Project",
        textAlign: "center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Description",
        derivedValue:
          "description=description=description=description=Description=description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '500px', textOverflow: "ellipsis" },
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignEmployees",
        fieldName: "assignEmployees",
        type: "relateAutoComplete",
        placeholder: "assignEmployees",
        value: "",
        label: "Assign Employees",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Team Members",
        derivedValue:
          "assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=assignEmployees=Team_Members=Team_Members=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignEmployees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: true,
        textAlign: "center",
        style: { width: '300px', textOverflow: "ellipsis" },
        show: true,
        field: "assignEmployees",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teamSize",
        fieldName: "teamSize",
        type: "number",
        placeholder: "teamSize",
        value: "",
        label: "Team Size",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Team Size",
        derivedValue: "teamSize=teamSize=teamSize=teamSize=teamSize=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teamSize",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "teamSize",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "prefix",
        fieldName: "prefix",
        type: "text",
        placeholder: "prefix",
        value: "",
        label: "Project Key",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Project Key",
        derivedValue: "prefix=prefix=prefix=prefix=prefix=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "prefix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "prefix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientName",
        fieldName: "clientName",
        type: "text",
        placeholder: "Client Name",
        value: "",
        label: "Client Name",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Client Name",
        derivedValue: "clientName=clientName=clientName=clientName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "clientName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientPhoneNumber",
        fieldName: "clientPhoneNumber",
        type: "text",
        placeholder: "clientPhoneNumber",
        value: "",
        label: "Client Phone Number",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Client Phone Number",
        derivedValue:
          "clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=clientPhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientPhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "clientPhoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientAddress",
        fieldName: "clientAddress",
        type: "textarea",
        placeholder: "clientAddress",
        value: "",
        label: "Client Address",
        width: "200px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Client Address",
        derivedValue: "clientAddress=clientAddress=clientAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "clientAddress",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "showsTaskId",
        fieldName: "showsTaskId",
        type: "radio",
        placeholder: "Show TaskId",
        value: "",
        label: "Show TaskId",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Show TaskId",
        derivedValue:
          "showsTaskId=showsTaskId=showsTaskId=showsTaskId=showsTaskId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "showsTaskId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        textAlign: "center",
        show: true,
        field: "showsTaskId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const UpdateStatussubmit1 = (item) => {
    let x = ["Status"];
    let objprojects = {};

    objprojects["_id"] = item[0]["_id"];
    for (let x2 of x) {
      objprojects[x2] = item[0][x2];
    }
    let formFields = this.getFormFields();

    formFields = formFields.filter((y) => x.includes(y.name));

    this.setState({
      openNewUserModal: true,
      item: objprojects,
      newFormFields: formFields,
    });
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.projects}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "Pending",
      action: saveDataToServer,
      options: [
        {
          label: "Pending",
          field: "status",
          value: "Pending",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Active",
      action: saveDataToServer,
      options: [
        {
          label: "Active",
          field: "status",
          value: "Active",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Closed",
      action: saveDataToServer,
      options: [
        {
          label: "Closed",
          field: "status",
          value: "Closed",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
  ];

  //Displaying custom action icons approve and reject based on conditions
  const customActionNotesButton = (item, getData, filters) => {
    return (
      <>
          <AddNotes
            item={item}
            getDataFromServer={getData}
            filterCriteria={filters}
          />
      </>
    )
  }

  const getSprintFields = () => {
    let data = [
      {
        show: false,
        mobile: true,
        textAlign: "center",
        width: 47,
        field: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 50,
        field: 'Sno',
        fieldType: 'text',
        type: 'text',
        header: 'SNo',
        filter: true,
        sortable: false,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 90,
        field: 'name',
        fieldType: 'text',
        type: 'text',
        header: 'Sprints',
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 90,
        field: 'taskID',
        fieldType: 'text',
        type: 'text',
        header: 'Task IDs',
        filter: true,
        sortable: true,
      }
    ];
    return data;
  }

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Projects'
          // apiUrl={apiCalls.Projects}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.projects}
          globalSearch={
            "name/status/description/companyEmpId/prefix/clientName/clientPhoneNumber/clientAddress/showsTaskId/teamLeadName"
          }
          displayName="Projects"
          type="Projects"
          routeTo={apiCalls.projects}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.projects?.toLowerCase()}
          apiUrl={apiCalls.projects}
          selectedId={params.id}
          defaultCriteria={[
            { key: "status", value: ["Active", "Pending"], type: "in" },
          ]}
          displayActionsInTable={"actions"}
          customActionNotesButton={customActionNotesButton}
          getSprintFields={getSprintFields}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="projects"
          apiUrl={apiCalls.projects}
        />
      ) : null}
    </span>
  );
};

export default Projects;
