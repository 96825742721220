import React from 'react';
import dateFormats from '../../UI/FormatDate/formatDate';
import config from '../../../config/config';

function FunnelFilter(filterEvent, fieldTypeObj, sortOrd, sortField, type, rows, page, cri, globalSearch) {
  
 
  const formFilter = () => {
    // let currentPage = 1;
    // if (filterEvent && filterEvent.first && filterEvent.rows) {
    //   currentPage = filterEvent.first / filterEvent.rows + 1;
    // }

    let filterCriteria = {};
    let criteria = [];

    if (cri.length > 0) {
      criteria = cri;
    }

    filterCriteria['page'] = page;
    filterCriteria['limit'] = rows ? rows : filterEvent.rows;
    filterCriteria['sortfield'] = sortField ? sortField : 'created';

    if (sortOrd !== undefined) {
      filterCriteria['direction'] = sortOrd == 1 ? 'asc' : 'desc';
    }

    if (filterEvent && filterEvent.filters) {
      let filters = Object.keys(filterEvent.filters);

      if (filters && filters.length > 0) {
        filters.forEach(filter => {
          let filterArray = filterEvent.filters[filter]?.constraints;
          if (filterArray && filterArray.length > 0) {
            filterArray.forEach(element => {

              if(element.value!=null && (element.matchMode=='dateIs' || element.matchMode=='dateBefore' || element.matchMode=='dateAfter')){
                let dateFilters = formatDateFilters(element, filter);
                dateFilters.forEach((dateFilter) => {
                  updateOrAddCriteria(criteria, dateFilter, filter);
                });
              } else if (element.value && filter && fieldTypeObj) {
                let isRelateAutoComplete = fieldTypeObj[filter] === "relateautocomplete";
                if (fieldTypeObj[filter] === "relateautocomplete") {
                  filter = filter + 'Search';
                }
                let valueAndType = getValueAndType(filter, fieldTypeObj, element);
                if (isRelateAutoComplete) {
                  valueAndType.type = 'regexOr';
                }
                if (Array.isArray(valueAndType)) {
                  let tempCriteria = [];
                  valueAndType.forEach(x => tempCriteria.push(updateOrAddCriteria(criteria, { key: filter, value: x.value, type: x.type }, filter)));
                  criteria = tempCriteria;
                } else {
                  criteria = updateOrAddCriteria(criteria, { key: filter, value: valueAndType.value, type: valueAndType.type }, filter);
                }
              }

            });
          }
        });
      }
    }
    filterCriteria['criteria'] = criteria;
    if (globalSearch) {
      filterCriteria.globalSearch = globalSearch;
    }
    return filterCriteria;
  };

  const updateOrAddCriteria = (criteria, newCriteria, field) => {
    let index;
    if (fieldTypeObj[field] === 'date') {
      index = criteria.findIndex(c => c.key === newCriteria.key && c.type === newCriteria.type);
    } else {
      index = criteria.findIndex(c => c.key === newCriteria.key);
    }
    if (index > -1) {
      if (newCriteria?.value) {
        if (Array.isArray(newCriteria.value)) {
          if (newCriteria.value?.length > 0) {
            criteria[index] = newCriteria;
          }
          else {
            criteria = criteria.filter((ct, i) => i !== index)
          }
        } else {
          criteria[index] = newCriteria;
        }
      } else {
        criteria.splice(index, 0)
      }
    } else {
      if (newCriteria?.value) {
        if (Array.isArray(newCriteria.value)) {
          if (newCriteria.value?.length > 0) {
            criteria.push(newCriteria);
          }
        } else {
          criteria.push(newCriteria);
        }
      }
    }
    return criteria
  };

  const formatDateFilters = (element, field) => {

    //for created and updated date fields
    if (field == "created" || field == "updated" || field == "date") {
      const val = element.value[0];
      const inputDate = dateFormats.formatDate(val, config.dayYearDateFormat);
      const appAfter = inputDate + 'T23:59:59Z'
      const appBefore = inputDate + 'T00:00:00Z'
      const previousDate = getPreviousDate(inputDate);
      const inputDate2 =dateFormats.formatDate(element.value[1], config.dayYearDateFormat);

      if (element.matchMode == 'dateIs') {
        if(element.value[1] != null){
          return [{ key: field, value: `${inputDate}T00:00:00Z`, type: 'gte' }, { key: field, value: `${inputDate2}T23:59:59Z`, type: 'lte' }]
        }
        else{
        return [{ key: field, value: appBefore, type: 'gte' }, { key: field, value: appAfter, type: 'lte' }]
        }
      }
      if(element.matchMode == 'dateAfter'){
        if(element.value[1] != null){
          return [{ key: field, value: `${inputDate2}T23:59:59Z`, type: 'gte' }]
        }
        else{
        return[{key : field, value:`${inputDate}T23:59:59Z`, type: 'gt'}]
        }
      }
      if(element.matchMode == 'dateBefore'){
        if(element.value[1] != null){
          return [{ key: field, value: `${inputDate}T00:00:00Z`, type: 'lt' }]
        }
        else{
        return[{key : field, value:`${previousDate}T23:59:59Z`, type: 'lte'}]
        }
      }
     
    }

    //other date fields
    else {
      //when a range is selected
      if (element.value.length == 2 && element.value[1] != null) {
        const start =dateFormats.formatDate(element.value[0], config.dayYearDateFormat);
        const initialDate = getPreviousDate(start);
        const inputDateForNext = dateFormats.formatDate(element.value[1], config.dayYearDateFormat);
        const inputDate = dateFormats.formatDate(element.value[0], config.dayYearDateFormat);
        const previousDate = getPreviousDate(inputDate);
        if (element.matchMode == 'dateIs') {
        return [
          { key: field, value: initialDate, type: 'gte' },
          { key: field, value: inputDateForNext, type: 'lte' }
        ]; 
        }
        if (element.matchMode == 'dateBefore') {
          return [{ key: field, value: previousDate, type: 'lte' }]
        }
        if (element.matchMode == 'dateAfter') {
          return [{ key: field, value: inputDateForNext, type: 'gt' }]
        }
      }
      //when a single date is selected
      else {
        const val = element.value[0];
        const inputDate = dateFormats.formatDate(val, config.dayYearDateFormat);
        const previousDate = getPreviousDate(inputDate);

        if (element.matchMode == 'dateIs') {
          return [{ key: field, value: previousDate, type: 'gte' }, { key: field, value: inputDate, type: 'lte' }]
        }
        if (element.matchMode == 'dateBefore') {
          return [{ key: field, value: previousDate, type: 'lte' }]
        }
        if (element.matchMode == 'dateAfter') {
          return [{ key: field, value: inputDate, type: 'gte' }]
        }
      }
    }

  }

  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const getValueAndType = (field, fieldTypeObj, filterObj) => {
    let type = filterObj?.matchMode;
    if (type) {
      switch (type) {
        case 'equals':
          type = 'eq';
          break;
        case 'startsWith':
          type = 'sw';
          break;
        case 'endsWith':
          type = 'ew';
          break;
        case 'notEquals':
          type = 'ne'
          break;
        case 'notContains':
          type = 'ne';
          break;
        case 'lt':
          type = 'lt';
          break;
        case 'gt':
          type = 'gt';
          break;
        case 'gte':
          type = 'gte';
          break;
        case 'lte':
          type = 'lte';
          break;
      
        default:
          type = 'regexOr';
      }
    }
    if (fieldTypeObj[field]) {

      if (fieldTypeObj[field] == 'dropdown') {
        if (filterObj.value.length > 0) {
          return { value: filterObj.value, type: 'in' };
        }
        else {
          return { value: [], type: 'in' };
        }
      }
      else if (fieldTypeObj[field] == 'radio' && filterObj.value.length > 0) {
        const valArray=filterObj.value
        return { value: valArray, type: 'in' };
      } 
      else {
        return { value: filterObj.value, type: type };
      }
    }
    return { value: filterObj.value, type: type };
  };

  return formFilter();

}

export default FunnelFilter;
