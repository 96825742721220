import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Attendences = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Attendences");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdBy=createdBy=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "Text",
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedBy=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "Text",
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        disabled: true,
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "text",
        placeholder: "Employee Name",
        value: "",
        label: "Employee Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Employee Name",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=EmployeeName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Work Type",
        value: "",
        label: "Work Type",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Work Type",
        derivedValue: "type=type=type=type=type=type=WorkType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Regular", value: "Regular", color: "primary" },
          {
            label: "Client Location",
            value: "Client Location",
            color: "primary",
          },
          {
            label: "Work From Home",
            value: "Work From Home",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Regular", 
            value: "Regular", 
            color: "primary" },
          {
            label: "Client Location",
            value: "Client Location",
            color: "primary",
          },
          {
            label: "Work From Home",
            value: "Work From Home",
            color: "primary",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "date",
        fieldName: "date",
        type: "date",
        placeholder: "Date",
        value: "",
        label: "Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Date",
        derivedValue: "date=date=date=date=Date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "inTime",
        fieldName: "inTime",
        type: "String",
        placeholder: "InTime",
        value: "",
        label: "In Time",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "In Time",
        derivedValue: "inTime=inTime=inTime=InTime=InTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "inTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "inTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "outTime",
        fieldName: "outTime",
        type: "String",
        placeholder: "OutTime",
        value: "",
        label: "Out Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Out Time",
        derivedValue: "outTime=outTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "outTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "false",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "outTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "checkouts",
        fieldName: "checkouts",
        type: "number",
        placeholder: "No Of Checkouts",
        value: "",
        label: "No Of Checkouts",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "No Of Checkouts",
        derivedValue:
          "checkouts=checkouts=checkOutList=checkOutMissed=checkOutMissed=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "checkouts",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "checkouts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      
      {
        name: "workingHours",
        fieldName: "workingHours",
        type: "number",
        placeholder: "Working Hours",
        value: "",
        label: "Working Hours",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Working Hours",
        derivedValue: "workingHours=workingHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "workingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "workingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "punchRecords",
        fieldName: "punchRecords",
        type: "textarea",
        placeholder: "Punch Records",
        value: "",
        label: "Punch Records",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Punch Records",
        derivedValue: "punchRecords=punchRecords=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "punchRecords",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        style: { width: '300px', textOverflow: "ellipsis" },
        show: true,
        field: "punchRecords",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "timesheetHours",
        fieldName: "timesheetHours",
        type: "number",
        placeholder: "Timesheet Hours",
        value: "",
        label: "Timesheet Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Timesheet Hours",
        derivedValue:
          "timesheetHours=timesheetHours=timesheetHours=timesheetHours=timesheetHours=timesheetHours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "timesheetHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "timesheetHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "uniqueID",
        placeholder: "Employee Id",
        value: "",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isAddToIndex: false,
        isBulkUploadField: false,
        uniqueIdLength: "12",
        textAlign: "center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      }
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "text",
        placeholder: "Employee Name",
        value: "",
        label: "Employee Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=EmployeeName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "date",
        fieldName: "date",
        type: "date",
        placeholder: "Date",
        value: "",
        label: "Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "date=date=date=date=Date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "inTime",
        fieldName: "inTime",
        type: "String",
        placeholder: "InTime",
        value: "",
        label: "In Time",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "inTime=inTime=inTime=InTime=InTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "inTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "outTime",
        fieldName: "outTime",
        type: "String",
        placeholder: "OutTime",
        value: "",
        label: "Out Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "outTime=outTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "outTime",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "textarea",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Comments",
        derivedValue:
          "comments=comments=comments=comments=comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "Center",
        style: { width: '500px', textOverflow: "ellipsis" },
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "Text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        id: "createdByName",
        derivedValue: "createdByName=createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "Text",
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "relateAutoComplete",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "text",
        placeholder: "Employee Name",
        value: "",
        label: "Employee Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Employee Name",
        derivedValue:
          "employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=employeeName=EmployeeName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Work Type",
        value: "",
        label: "Work Type",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Work Type",
        derivedValue: "type=type=type=type=type=type=WorkType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Permanent", 
            value: "Permanent", 
            color: "primary" },
          {
            label: "Contract",
            value: "Contract",
            color: "primary",
          }
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Permanent",
            value: "Permanent",
            color: "primary"
          },
          {
            label: "Contract",
            value: "Contract",
            color: "primary",
          }
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "date",
        fieldName: "date",
        type: "date",
        placeholder: "Date",
        value: "",
        label: "Date",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Date",
        derivedValue: "date=date=date=date=Date=Date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "inTime",
        fieldName: "inTime",
        type: "time",
        placeholder: "InTime",
        value: "",
        label: "In Time",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "In Time",
        derivedValue: "inTime=inTime=inTime=InTime=InTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "inTime",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "inTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "outTime",
        fieldName: "outTime",
        type: "time",
        placeholder: "OutTime",
        value: "",
        label: "Out Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Out Time",
        derivedValue: "outTime=outTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "outTime",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "false",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "outTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "checkouts",
        fieldName: "checkouts",
        type: "number",
        placeholder: "No Of Checkouts",
        value: "",
        label: "No Of Checkouts",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "No Of Checkouts",
        derivedValue:
          "checkouts=checkouts=checkOutList=checkOutMissed=checkOutMissed=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "checkouts",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "false",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "checkouts",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "workingHours",
        fieldName: "workingHours",
        type: "number",
        placeholder: "WorkingHours",
        value: "",
        label: "Working Hours",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Working Hours",
        derivedValue: "workingHours=workingHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "workingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "workingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "punchRecords",
        fieldName: "punchRecords",
        type: "textarea",
        placeholder: "Punch Records",
        value: "",
        label: "Punch Records",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Punch Records",
        derivedValue: "punchRecords=punchRecords=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "punchRecords",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "punchRecords",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "timesheetHours",
        fieldName: "timesheetHours",
        type: "number",
        placeholder: "Timesheet Hours",
        value: "",
        label: "Timesheet Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Timesheet Hours",
        derivedValue:
          "timesheetHours=timesheetHours=timesheetHours=timesheetHours=timesheetHours=timesheetHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "timesheetHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "timesheetHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "uniqueID",
        placeholder: "Employee Id",
        value: "",
        label: "Employee Id",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Employee Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        uniqueIdLength: "12",
        textAlign: "center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "filterDate",
        type: "date",
        placeholder: "FilterDate",
        label: "FilterDate",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "FilterDate",
        derivedValue: "filterDate=filterDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "filterDate",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD",
        fieldType: "Date",
        textAlign: "Center",
        show: false,
        field: "filterDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isLessThan6Hours",
        type: "number",
        placeholder: "IsLessThan6Hours",
        label: "IsLessThan6Hours",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "IsLessThan6Hours",
        derivedValue: "isLessThan6Hours=isLessThan6Hours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isLessThan6Hours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "isLessThan6Hours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isWeekEnd",
        type: "text",
        placeholder: "IsWeekEnd",
        label: "IsWeekEnd",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "IsWeekEnd",
        derivedValue: "isWeekEnd=isWeekEnd=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isWeekEnd",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "isWeekEnd",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "text",
        placeholder: "Role",
        label: "Role",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Role",
        derivedValue: "role=role=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "role",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "role",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "wfhApproved",
        type: "radio",
        placeholder: "WfhApproved",
        label: "WfhApproved",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "WfhApproved",
        derivedValue: "wfhApproved=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "wfhApproved",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: false,
        field: "wfhApproved",
        showOrHideFields: [],
        fieldName: "wfhApproved",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "WFH",
        type: "radio",
        placeholder: "WFH",
        label: "WFH",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "WFH",
        derivedValue: "WFH=WFH=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "WFH",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: false,
        field: "WFH",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "checkOutMissed",
        type: "radio",
        placeholder: "CheckOutMissed",
        label: "CheckOutMissed",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "CheckOutMissed",
        derivedValue: "checkOutMissed=checkOutMissed=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "checkOutMissed",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: false,
        field: "checkOutMissed",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromBulk",
        type: "text",
        placeholder: "FromBulk",
        label: "FromBulk",
        width: "130px",
        addFormOrder: 26,
        editFormOrder: 26,
        header: "FromBulk",
        derivedValue: "fromBulk=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fromBulk",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "fromBulk",
        showOrHideFields: [],
        fieldName: "fromBulk",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.attendences}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ]

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={isEditPermission}
          // addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Attendences'
          // apiUrl={apiCalls.Attendences}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.attendences}
          globalSearch={"employeeName / type"}
          displayName="Attendences"
          type="Attendences"
          routeTo={apiCalls.attendences}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          colWidth="narrow"
          apiResponseKey={apiCalls.attendences?.toLowerCase()}
          apiUrl={apiCalls.attendences}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="attendences"
          apiUrl={apiCalls.attendences}
        />
      ) : null}
    </span>
  );
};

export default Attendences;
