import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import DoInputField from '../../../Form/Fields/DoInputField';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import fetchMethodRequest from '../../../../config/service';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import config from '../../../../config/config';

const schema = yup.object().shape({
    otp: yup.string().required('Otp is required')
        .min(6, 'Otp must be at least 6 characters')
        .max(6, 'Otp must be at most 6 characters')
});

const Authenticator = ({ template, logo }) => {
    const [isLoginForVerification, setIsLoginForVerification] = useState(false);

    let subHeader = 'An OTP has been sent to your registered email address. Please enter the OTP below to verify your account.';
    let loggedEmployee = JSON.parse(localStorage.getItem('employee'));

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sendOTP = (values) => {
        if (values) {
            const obj = {
                otp: values.otp,
                email: loggedEmployee?.email,
                entityType: config.entityType,
                type: 'Authenticator'
            };
            return fetchMethodRequest('POST', 'auth/verifyotp', obj)
                .then(res => {
                    if (res && res.qrCode) {
                        delete res.respCode;
                        localStorage.setItem('authenticatorDetails', JSON.stringify(res))
                        setIsLoginForVerification(true);
                    } else if (res && res.errorMessage) {
                        showToasterMessage(res.errorMessage, 'error');
                    }
                })
                .catch(error => {
                    showToasterMessage('An error occurred while sending OTP');
                });
        }
    };


    return (
        <div className='login-container'>
            <div className='login4'>
                <div className='card'>
                    <div className='card-body p-4'>
                        <div className="container-fluid mb-3">
                            <h4 className="text-center header-message">Please check Your Email</h4>
                            <p>{subHeader}</p>
                        </div>

                        <div className="w-100">
                            <form className="form" onSubmit={handleSubmit(sendOTP)}>
                                <div className="mb-3">
                                    <Controller
                                        name="otp"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoInputField
                                                input={field}
                                                id={field.id}
                                                field={field}
                                                name={field.name}
                                                type="text"
                                                fieldState={fieldState}
                                                errors={errors}
                                                placeholder="otp"
                                                style={{ width: '23rem' }}
                                            />
                                        )}
                                    />
                                </div >
                                <div className="account__btns" style={{ width: '100%' }}>
                                    <Button className="ml-auto" style={{ width: '100%' }}
                                        color="primary" type='submit'>
                                        Sign in
                                    </Button>
                                </div>
                                <div className="text-left">
                                    <a href="/two_step_verification">Back to verification options...</a>
                                </div>

                                {isLoginForVerification ? <Navigate to="/verify_authenticator" /> : null}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Authenticator;
