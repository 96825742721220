import React from 'react';
import TwoStepVerification from './Two-stepVerification';
import configImages from '../../../../config/configImages';

const Verification = () => (
    <div>
        <div className='common-login-container'>
            <div className='login1' style={{ width: '30rem' }}>
                <div className='card'>
                    <div className='card-body'>
                        <div className='text-center w-100'>
                            <img src={configImages.loginImage} className='login-logo'></img>
                        </div>
                        <TwoStepVerification onSubmit />
                    </div >
                </div >
            </div >
        </div >

    </div >
);

export default Verification;
